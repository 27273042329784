import React, { useState } from 'react'
import {
  CFormSelect,
  CSmartPagination,
  CButton,
  CFormInput,
  CFormLabel,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilFilter } from '@coreui/icons'
import PageCounter from './PageCounter'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import Download from './Download'
import { useMutation } from '@tanstack/react-query'
import { uploadDocs } from '../../../utils/commonActions'
import api from '../../../APIs/private'
import qs from 'qs'
import { useLocation } from 'react-router-dom'

const Pagination: any = ({
  open,
  setOpen,
  current,
  count,
  length,
  handlePageChange,
  handleLengthChange,
  inputRef,
  freeText,
  handleChangeSubmit,
  start,
  reportId,
  isExportAllowed,
}: any) => {
  const { rows } = useAppSelector((state: RootState) => state.list)
  const { visualizeReport } = useAppSelector((state: RootState) => state.list)
  const location = useLocation()
  const query = location.search
  const { queryName } = qs.parse(query, { ignoreQueryPrefix: true }) || {} 

  const uploadDocsMutation = useMutation({
    mutationFn: async (e: any) => {
      await uploadDocs({
        acceptedFile: e?.target?.files?.[0],
        fileName: e?.target?.files?.[0].name,
        type: 'PROMO_FULFILLMENT',
      })
      setTimeout(() => window.location.reload(), 1500)
    },
  })

  const handleBulkTransfer = useMutation({
    mutationFn: async () => {
      const res = await api.post(`/promoBulkAirdrop`, {
        promoFulfillmentIds: rows?.map((row: any) => row?.promoFulfillmentId)
      })
      return res?.data?.promoFulfillmentProcessed || []
    },
    onSuccess: (data) => {
      setTimeout(() => {
        const res = Object.entries(data).map(([key, value]) => ({
          key,
          ...(value || {}),
        }))
        const anyFailed = res?.some((d: any) => d?.status === 'FAILED')
        if (anyFailed) {
          window.alert('Failed to transfer some promo fulfillments')
        }
        window.location.reload()
      }, 1500)
    },
  })

  return (
    <div className="d-flex justify-content-between align-items-start mt-2">
      <div className="d-flex">
        {!visualizeReport && (
          <CFormInput
            ref={inputRef}
            value={freeText}
            className="me-2"
            placeholder="Search"
            onChange={(e: any) => handleChangeSubmit(e.target.value)}
          />
        )}
        <CButton className="text-nowrap me-2" onClick={() => setOpen(true)}>
          <CIcon icon={cilFilter} />
        </CButton>
        {isExportAllowed && <Download reportId={reportId} />}
        {queryName === 'promo_fulfillments' && (
          <>
            <CFormLabel className="pretty-file ms-2 my-0 bg-primary w-100 rounded-3 text-white cursor-pointer text-align-center d-flex align-items-center justify-content-center">
              {uploadDocsMutation.isLoading
                ? 'Uploading...'
                : 'Upload CSV File'}
              <CFormInput
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  uploadDocsMutation.mutate(e)
                }
                accept=".csv"
              />
            </CFormLabel>
            <CButton
              className="text-nowrap ms-2"
              onClick={() =>handleBulkTransfer.mutate()}
            >
              Bulk Transfer
            </CButton>
          </>
        )}
      </div>
      {count > 0 ? (
        <div className="d-flex align-items-start">
          <div className="me-2 mt-2">
            <PageCounter count={count} start={start} length={length} />
          </div>
          {!visualizeReport && (
            <>
              <CSmartPagination
                className="hover"
                activePage={current + 1}
                pages={Math.ceil(count / length)}
                onActivePageChange={(item: any) =>
                  handlePageChange && item - 1 !== -1
                    ? handlePageChange(item - 1)
                    : null
                }
              />

              <div className="ms-2">
                <CFormSelect
                  defaultValue={length}
                  onChange={handleLengthChange}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </CFormSelect>
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default Pagination
