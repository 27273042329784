import React, { useEffect, useMemo, useState } from 'react'
import PageLayout from '../../layout/PageLayout'
import { useParams, useHistory } from 'react-router'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'
import privateAPI from '../../APIs/private'
import Spinner from '../../components/Spinner'
import { CButton, CFormInput, CFormLabel, CNav, CNavItem, CNavLink } from '@coreui/react-pro'
import GenericTable from '../../components/GenericTable'
import { getQueryData } from '../../utils/commonActions'
import Select from '../assets/components/Select'
import { formatToPT } from '../../utils/formatUtils/utils'

const getDocument = async (id: string) => {
  const response = await privateAPI.get(`/queries/documents/${id}`)
  return response?.data?.rows?.[0] || {}
}

const DocumentDetail: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState('CEA')
  const { id }: any = useParams()
  const history = useHistory()
  const methods = useForm()
  const { reset, register, handleSubmit } = methods

  // document data
  const { data, isInitialLoading: documentLoading } = useQuery({
    queryKey: ['documents', id],
    queryFn: () => getDocument(id),
    enabled: +id > 0,
  })

  const document = useMemo(() => data, [data])

  const { userId, entityId, cashExternalAccountId, fileUrl, createdAt, updatedAt } = document || {}

  const { data: userData, isInitialLoading: userLoading } = useQuery({
    queryKey: ['documents_user', userId],
    queryFn: async () =>
      await getQueryData('users', {
        userId: { filterMin: userId, filterMax: userId },
        fullName: {},
      }),
    enabled: !!userId,
  })

  const { fullName } = userData?.rows?.[0] || {}

  const { data: entityData, isInitialLoading: entityLoading } = useQuery({
    queryKey: ['documents_entity', entityId],
    queryFn: async () =>
      await getQueryData('entities', {
        entityId: { filterMin: entityId, filterMax: entityId },
        name: {}
      }),
    enabled: !!entityId,
  })

  const { name } = entityData?.rows?.[0] || {}

  const { data: cashExternalAccountData, isInitialLoading: cashExternalAccountLoading } = useQuery({
    queryKey: ['documents_cash_external_account', cashExternalAccountId],
    queryFn: async () =>
      await getQueryData('cash_external_accounts', {
        cashExternalAccountId: { filterMin: cashExternalAccountId, filterMax: cashExternalAccountId },
        bankName: {},
        name: {},
        legalName: {},
      }),
    enabled: !!cashExternalAccountId,
  })

  const { bankName } = cashExternalAccountData?.rows?.[0] || {}

  const { data: userEntities, isInitialLoading: userEntitesLoading } = useQuery({
    queryKey: ['documents_entities', entityId],
    queryFn: async () =>
      await getQueryData('entities', {
        userId: { filterMin: userId, filterMax: userId },
        entityId: {},
        name: {},
        status: {}
      }),
    enabled: !!userId,
  })

  const { data: userCashExternalAccounts, isInitialLoading: userCashExternalAccountsLoading } = useQuery({
    queryKey: ['documents_cash_external_accounts', cashExternalAccountId],
    queryFn: async () =>
      await getQueryData('cash_external_accounts', {
        userId: { filterMin: userId, filterMax: userId },
        bankName: {},
        amlStatus: {},
        cashExternalAccountId: {},
        accountNumberSafe: {},
        isActive: {}
      }),
    enabled: !!userId,
  })

  const handleAssociateButtonClick = useMutation({
    mutationFn: async (data) => {
      let res = null
      if (activeTab === 'CEA') {
        await privateAPI.post(`/queries/documents/${id}`, { cashExternalAccountId: data?.cashExternalAccountId, documentId: +id })
        res = await getDocument(id)
      } else {
        await privateAPI.post(`/queries/documents/${id}`, { entityId: data?.entityId, documentId: +id })
        res = await getDocument(id)
      }
      return res
    },
    onSuccess: response => {
      if (!!response?.cashExternalAccountId) {
        history.push(`/cash_external_accounts/${response?.cashExternalAccountId}`)
      } else {
        history.push(`/entities/${response?.entityId}`)
      }
    },
  })

  const { rows: userCashExternalAccountsRows, columns: userCashExternalAccountsColumns } = userCashExternalAccounts || {}
  const { rows: userEntitesRows, columns: userEntitiesColumns } = userEntities || {}

  
  // reset form data when document and segment data is loaded
  useEffect(() => reset(document), [document, reset])
  
  const handleClick = ({ cashExternalAccountId, entityId }: any) => {
    if (activeTab === 'CEA') {
      history.push(`/cash_external_accounts/${cashExternalAccountId}`)
    } else {
      history.push(`/entities/${entityId}`)
    }
  }

  // rows and columns for the table
  const rows = activeTab === 'CEA' ? userCashExternalAccountsRows || [] : userEntitesRows || []
  const arr = activeTab === 'CEA' ? userCashExternalAccountsColumns?.map((col: any) => ({ ...col, key: col.name, renderer: col?.name === 'isActive' ? 'Boolean' : !!col?.renderer ? col?.renderer : 'String' })) : userEntitiesColumns?.map((col: any) => ({ ...col, key: col.name }))
 
  // add associate button to the columns
  arr?.push({ key: 'associate', name: 'associate', renderer: 'Button', label: 'Associate' })
  // remove userId column
  const columns = arr?.filter((col: any) => col?.name !== 'userId')
  // move cashExternalAccountId column to the first position
  columns?.forEach((item: any,i: number) => {
    if (item.name === 'cashExternalAccountId') {
      columns.splice(i, 1);
      columns.unshift(item);
    }
  })

  rows?.forEach((row: any) => {
    row.associate = true
    return row
  })
  
  if (documentLoading || userLoading || entityLoading || cashExternalAccountLoading || userEntitesLoading || userCashExternalAccountsLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <>
      <PageLayout>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(() => null)}>
            <div className='d-flex justify-content-between'>
              <h4>Document No. {id}</h4>
              <CButton onClick={() => window.open(fileUrl)}>View File</CButton>
            </div>
            <div className="row g-3 mb-5">
              {!!userId && <div className='col-md-3'>
                <CFormLabel>User</CFormLabel>
                <Select
                  readOnly
                  watchValue={fullName}
                  query="users"
                  id={userId}
                  {...register('fullName')}
                  ref={null}
                  />
              </div>}
              {!!cashExternalAccountId && <div className='col-md-3'>
                <CFormLabel>Cash External Account</CFormLabel>
                <Select
                  readOnly
                  watchValue={bankName}
                  query="cash_external_accounts"
                  id={cashExternalAccountId}
                  ref={null}
                  />
              </div>}
              {!!entityId && <div className='col-md-3'>
                <CFormLabel>Entity</CFormLabel>
                <Select
                  readOnly
                  watchValue={name}
                  query="entities"
                  id={entityId}
                  ref={null}
                  />
              </div>}
              <div className="col-md-3">
                <CFormLabel>Updated At</CFormLabel>
                <CFormInput value={formatToPT(updatedAt, 'MM/DD/YYYY')} disabled />

              </div>
              <div className="col-md-3">
                <CFormLabel>Created At</CFormLabel>
                <CFormInput value={formatToPT(createdAt, 'MM/DD/YYYY')} disabled />

              </div>
              <div className="col-md-3">
                <CFormLabel>Document Type</CFormLabel>
                <CFormInput {...register('type')} disabled />
              </div>
            </div>
            {!!userId && <>
              <h4 className='mb-2'>Change Document Association</h4>
              <CNav variant="tabs" className='hover'>
                <CNavItem>
                  <CNavLink onClick={() => setActiveTab('CEA')} active={activeTab === 'CEA'} className={activeTab !== 'CEA' ? 'inactive' : ''}>
                    Cash External Accounts
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink onClick={() => setActiveTab('ENTITY')} active={activeTab === 'ENTITY'} className={activeTab !== 'ENTITY' ? 'inactive' : ''}>
                    Entities
                  </CNavLink>
                </CNavItem>
              </CNav>
              <GenericTable
                items={rows}
                columns={columns}
                handleClick={(item: any) => handleClick(item)}
                handleButtonClick={(item: any) => handleAssociateButtonClick.mutate(item)}
              />
            </>}
          </form>
        </FormProvider>
      </PageLayout>
    </>
  )
}

export default DocumentDetail
